<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px">
                <el-row type="flex" class="row-gap">
                    <el-col :span="4" class="col-right">
                        <el-tooltip class="item" effect="dark" content="启用时，若发生故障蜂鸣器会响" placement="right-start">
                            <span>蜂鸣器 <i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :offset="2">
                        <el-switch v-model="dataForm.buzzer" :active-value="'1'" :inactive-value="'0'"
                               @change="switchChangeEvent($event, 'buzzer')"/>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap">
                    <el-col :span="4" class="col-right">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                                content="启用时，负载过载会切换到市电；禁用时，则根据过载重启状态判断" >
                            <span>过载旁路 <i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :offset="2">
                        <el-switch v-model="dataForm.overloadBypass" :active-value="'1'" :inactive-value="'0'"
                               @change="switchChangeEvent($event, 'overloadBypass')"/>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap">
                    <el-col :span="4" class="col-right">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                                content="启用时，若负载过载，30分钟内重启三次；禁用时，若负载过载，直接关机" >
                            <span>过载重启 <i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :offset="2">
                        <el-switch v-model="dataForm.overloadRestart" :active-value="'1'" :inactive-value="'0'"
                               @change="switchChangeEvent($event, 'overloadRestart')"/>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap">
                    <el-col :span="4" class="col-right">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                                content="启用时，若逆变散热器温度大于80度，30分钟内重启三次；禁用时，若逆变散热器温度大于80度，直接关机" >
                            <span>过温重启 <i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :offset="2">
                        <el-switch v-model="dataForm.overTemperatureRestart" :active-value="'1'" :inactive-value="'0'"
                               @change="switchChangeEvent($event, 'overTemperatureRestart')"/>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';

export default {

    name: 'device-status-info',
    data() {

        return {

            command: {

                commandCodeR: 'get_flag_status',
                // 设置启用时的命令
                commandCodeEnableW: 'set_some_status_enable',
                // 设置禁用时的命令
                commandCodeDisableW: 'set_some_status_disable'
            },
            deviceSn: '',
            // 属性对应的指令实际值：1：使能，0：禁止.
            dataForm: {

                buzzer: '',
                overloadBypass: '',
                overloadRestart: '',
                overTemperatureRestart: ''
            },
            // 属性对应的写指令码，启用和禁用一致
            dataFormW: {

                buzzer: 'A',
                overloadBypass: 'B',
                overloadRestart: 'U',
                overTemperatureRestart: 'V'
            }
        }
    },
    methods: {

        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('状态指令读取中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        // 读指令成功渲染
                        this.dataForm = res.data;
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            mqttService.sendEsaioBusinessCommand(option);
        },
        switchChangeEvent(e, name) {

            if (e === '1') {

                // 下发启用指令
                this.submit(this.command.commandCodeEnableW, name);
            } else {

                // 下发禁用指令
                this.submit(this.command.commandCodeDisableW, name);
            }
        },
        /**
         * 提交设置指令
         * @param commandCodeW 指令名称
         * @param name 要下发的属性
         */
        submit(commandCodeW, name) {

            let writeData = {

                'enableDisableType' : this.dataFormW[name]
            };
            if (writeData.enableDisableType == null || writeData.enableDisableType === '') {

                message.error('参数错误');
                return;
            }
            this.cmdLoading = loading.loading('状态设置指令下发中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: commandCodeW,
                    data: writeData
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('状态设置指令下发成功');
                    } else {

                        message.error('状态设置指令下发失败');
                    }
                }
            };
            mqttService.sendEsaioBusinessCommand(option);
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%;
    .row-gap {

        margin-bottom: 30px;
        .col-right {

            text-align: right;
        }
    }
}
</style>
