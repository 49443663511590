<template>
    <el-dialog title="设备指令控制" :visible.sync="deviceCmdPageVisible" top="0"
        id="schf5kDeviceCmdIndexDialog" :close-on-press-escape="false" custom-class="cmd-dialog-style"
        @close="closeCmdPage" :close-on-click-modal="false" :destroy-on-close=true>
        <el-tabs v-model="activeName" ref="tabs" tab-position="left" class="module-cmd-tabs-style" @tab-click="handleClick">
            <el-tab-pane label="设备状态设置" name="deviceStatusInfo">
                <device-status-info ref="deviceStatusInfo"></device-status-info>
            </el-tab-pane>
            <el-tab-pane label="设备参数设置" name="deviceParameterInfo">
                <device-parameter-info ref="deviceParameterInfo"></device-parameter-info>
            </el-tab-pane>
            <el-tab-pane label="恢复出厂设置" name="controlParameterDefaultPage">
                <control-parameter-default ref="controlParameterDefaultPage"></control-parameter-default>
            </el-tab-pane>
            <el-tab-pane label="固件升级" name="Schf5kFirmwareUpgrade">
                <device-schf5k-firmware-upgrade ref="Schf5kFirmwareUpgrade"></device-schf5k-firmware-upgrade>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
import DeviceStatusInfo from "@/views/esaio/device/device-schf5k-cmd/device-status-info.vue";
import DeviceParameterInfo from "@/views/esaio/device/device-schf5k-cmd/device-parameter-info.vue";
import ControlParameterDefault from "@/views/esaio/device/device-schf5k-cmd/control-parameter-default.vue";
import DeviceSchf5kFirmwareUpgrade from "@/views/esaio/device/device-schf5k-cmd/device-schf5k-firmware-upgrade";

export default {

    name: 'device-schf5k-cmd',
    components: {
        DeviceParameterInfo,
        DeviceStatusInfo,
        ControlParameterDefault,
        DeviceSchf5kFirmwareUpgrade
    },
    props: {

        deviceInfo: {

            type: Object
        }
    },
    data() {

        return {

            deviceCmdPageVisible: false,
            activeName: 'deviceStatusInfo',
            isUpgrade: false
        };
    },
    methods: {

        initSchf5kCmdPage() {

            this.deviceCmdPageVisible = true;
            this.activeName = 'deviceStatusInfo';
            this.$nextTick(() => {

                this.$refs[this.activeName].initPage(this.deviceInfo.serialNumber);
            });
        },
        handleClick(tab) {

            this.$refs[tab.name].initPage(this.deviceInfo.serialNumber);
        },
        closeCmdPage() {

            this.$refs.tabs.$destroy();
            this.deviceCmdPageVisible = false;
            this.$emit('queryEsaioDeviceInfoList');
        }
    }
}
</script>

<style lang="scss" scoped>

.new-icon {

    float: right;
    width: 28px;
    margin-top: 5px;
}

::v-deep .el-dialog.cmd-dialog-style {

    width: 1070px;
    height: 660px;
}

.module-cmd-tabs-style {

    height: 560px;
}

/** 左边侧边栏滚动条 */
::v-deep .el-tabs__nav {

    height: 100%;
}
</style>
