<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="160px" size="mini">
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="交流输出额定电压">
                            <el-select v-model="dataForm.acOutputRatingVoltage">
                                <el-option v-for="(item, index) in acOutputRatingVoltageOption" :key="index" :label="`${item}V`" :value="item"/>
                            </el-select>
                            <el-button @click="submit('acOutputRatingVoltage')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="交流输出额定电流">
                            <el-input disabled v-model="dataForm.acOutputRatingCurrent">
                                <i slot="suffix" class="unit-info">A</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="交流输出额定频率" >
                            <el-select v-model="dataForm.acOutputRatingFrequency">
                                <el-option v-for="(item, index) in acOutputRatingFrequencyOption" :key="index" :label="`${item}Hz`" :value="item"/>
                            </el-select>
                            <el-button @click="submit('acOutputRatingFrequency')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="交流额定输出视在功率">
                            <el-input disabled v-model="dataForm.acOutputRatingApparentPower">
                                <i slot="suffix" class="unit-info">VA</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="当前市电最大充电电流">
                            <el-select v-model="dataForm.currentMaxAcChargingCurrent">
                                <el-option v-for="item in currentMaxAcChargingCurrentOption" :key="item.value" :label="`${item.name}A`" :value="item.value"/>
                            </el-select>
                            <el-button @click="submit('currentMaxAcChargingCurrent')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="交流输出额定有功功率">
                            <el-input disabled v-model="dataForm.acOutputRatingActivePower">
                                <i slot="suffix" class="unit-info">W</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="当前最大充电电流">
                            <el-select v-model="dataForm.currentMaxChargingCurrent">
                                <el-option v-for="item in currentMaxChargingCurrentOption" :key="item.value" :label="`${item.name}A`" :value="item.value"/>
                            </el-select>
                            <el-button @click="submit('currentMaxChargingCurrent')" type="primary"
                                       class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="电池额定电压">
                            <el-input disabled v-model="dataForm.batteryRatingVoltage">
                                <i slot="suffix" class="unit-info">V</i>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="输入电压范围">
                            <el-select v-model="dataForm.inputVoltageRange">
                                <el-option v-for="item in inputVoltageRangeOptions" :key="item.value" :label="item.name" :value="item.value"/>
                            </el-select>
                            <el-button @click="submit('inputVoltageRange')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="并联最大数量">
                            <el-input disabled v-model="dataForm.parallelMaxNumber"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="设备输出源优先级">
                            <el-select v-model="dataForm.outputSourcePriority">
                                <el-option v-for="item in outputSourcePriorityOption" :key="item.value" :label="item.name" :value="item.value"/>
                            </el-select>
                            <el-button @click="submit('outputSourcePriority')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="设备类型">
                            <el-select v-model="dataForm.machineType" disabled>
                                <el-option
                                    v-for="item in machineTypeOptions" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="充电优先级">
                            <el-select v-model="dataForm.chargerSourcePriority">
                                <el-option v-for="item in chargerSourcePriorityOptions" :key="item.value" :label="item.name" :value="item.value"/>
                            </el-select>
                            <el-button @click="submit('chargerSourcePriority')" type="primary"
                                   class="btn-box">设置</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="输出模式">
                            <el-select v-model="dataForm.outputMode" disabled>
                                <el-option
                                    v-for="item in outputModeOptions" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';

export default {

    name: 'device-parameter-info',
    data() {

        return {

            deviceSn: '',
            command: {

                commandCodeR: 'get_rate_info',
                // 以下为参数对应的写指令
                acOutputRatingVoltage: 'set_output_rating_voltage',
                acOutputRatingFrequency: 'set_output_rating_frequency',
                currentMaxAcChargingCurrent: 'set_utility_max_charging_current',
                currentMaxChargingCurrent: 'set_max_charging_current',
                inputVoltageRange: 'set_grid_working_range',
                outputSourcePriority: 'set_output_source_priority',
                chargerSourcePriority: 'set_charger_priority'
            },
            dataForm: {

                acOutputRatingVoltage: '',
                acOutputRatingFrequency: '',
                currentMaxAcChargingCurrent: '',
                currentMaxChargingCurrent: '',
                inputVoltageRange: '',
                outputSourcePriority: '',
                chargerSourcePriority: '',
                // 计算方式：5kw / 电网额定电压
                acOutputRatingCurrent: '',
                // 固定值：5000
                acOutputRatingApparentPower: '',
                // 固定值：5000
                acOutputRatingActivePower: '',
                // 固定值：48V
                batteryRatingVoltage: '',
                parallelMaxNumber: '',
                machineType: '',
                outputMode: '0'
            },
            acOutputRatingVoltageOption: ['220', '230', '240'],
            acOutputRatingFrequencyOption: ['50', '60'],
            currentMaxAcChargingCurrentOption: [
                {
                    name : '10',
                    value: '010'
                },
                {
                    name : '20',
                    value: '020'
                },
                {
                    name : '30',
                    value: '030'
                },
                {
                    name : '40',
                    value: '040'
                },
                {
                    name : '50',
                    value: '050'
                },
                {
                    name : '60',
                    value: '060'
                }
            ],
            currentMaxChargingCurrentOption: [
                {
                    name : '10',
                    value: '010'
                },
                {
                    name : '20',
                    value: '020'
                },
                {
                    name : '30',
                    value: '030'
                },
                {
                    name : '40',
                    value: '040'
                },
                {
                    name : '50',
                    value: '050'
                },
                {
                    name : '60',
                    value: '060'
                },
                {
                    name : '70',
                    value: '070'
                },
                {
                    name : '80',
                    value: '080'
                }
            ],
            inputVoltageRangeOptions: [
                {
                    // 90~280V
                    name : '设备(90V~280V)',
                    value: '00'
                },
                {
                    // 170~280V
                    name : '不间断电源(170v~280V)',
                    value: '01'
                }
            ],
            outputSourcePriorityOption: [
                {
                    name : '光伏优先',
                    value: '01'
                },
                {
                    name : 'SBU优先',
                    value: '02'
                }
            ],
            chargerSourcePriorityOptions: [
                {
                    name : '市电优先',
                    value: '00'
                },
                {
                    name : '光伏优先',
                    value: '01'
                },
                {
                    name : '市电+光伏',
                    value: '02'
                }
            ],
            outputModeOptions: [
                {
                    name : '单机输出',
                    value: '0'
                },
                {
                    name : '并机输出',
                    value: '1'
                },
                {
                    name : '三相输出1相',
                    value: '2'
                },
                {
                    name : '三相输出2相',
                    value: '3'
                },
                {
                    name : '三相输出3相',
                    value: '4'
                }
            ],
            machineTypeOptions: [
                {
                    name : '并网',
                    value: '00'
                },
                {
                    name : '离网',
                    value: '01'
                },
                {
                    name : '混合',
                    value: '10'
                }
            ]
        }
    },
    methods: {

        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('设备参数指令读取中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            let that = this;
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        // 读指令成功渲染
                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            mqttService.sendEsaioBusinessCommand(option);
        },
        submit(name) {

            // 当前设置的字段
            let writeData = {};
            writeData[name] = this.dataForm[name];
            if (writeData[name] == null || writeData[name] === '') {

                message.error('参数错误');
                return;
            }
            this.cmdLoading = loading.loading('设备参数设置指令下发中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command[name],
                    data: writeData
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('设备参数设置指令下发成功');
                    } else {

                        message.error('设备参数设置指令下发失败');
                    }
                }
            };
            mqttService.sendEsaioBusinessCommand(option);
        },
        initSuccessCallBack(data) {

            this.dataForm = data;
            // 处理特殊字段
            this.dataForm.acOutputRatingVoltage = parseInt(data.acOutputRatingVoltage) + '';
            this.dataForm.acOutputRatingFrequency = parseInt(data.acOutputRatingFrequency) + '';
            this.dataForm.acOutputRatingCurrent = (5000 / Number(data.acOutputRatingVoltage)).toFixed(2);
            this.dataForm.inputVoltageRange = '0' + data.inputVoltageRange;
            this.dataForm.chargerSourcePriority = '0' + data.chargerSourcePriority;
            this.dataForm.outputSourcePriority = '0' +  data.outputSourcePriority;
            this.dataForm.currentMaxAcChargingCurrent = '0' +  data.currentMaxAcChargingCurrent;
        },
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 0 4%;
    overflow-y: auto;
    .btn-box {

        margin-left: 15px;
    }
    .el-input, .el-select {

        width: 200px;
    }
    .unit-info {

        font-style:normal;
        margin-right: 10px;
    }
}
</style>
