<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px">
                <el-row type="flex" class="row-gap" align="middle">
                    <el-col :span="6">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                                    content="注意：固件升级过程中，逆变器会停止供电，请谨慎操作" >
                            <span>DSP版本<i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                        <span>当前版本 {{dataForm.dspCurVersion}}</span>
                    </el-col>
                    <el-col v-if="dataForm.dspCurVersion && isDspNew" :span="6">
                        <span>新版本 {{dspLastVersion}}</span>
                    </el-col>
                    <el-col v-if="dataForm.dspCurVersion && isDspNew" :span="6">
                        <el-button @click="upgrade('DSP')" type="primary" size="mini">升级</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" class="row-gap" align="middle">
                    <el-col :span="6">
                        <el-tooltip class="item" effect="dark" placement="right-start"
                                content="注意：固件升级过程中，逆变器会停止供电，请谨慎操作">
                            <span>ARM版本<i class="el-icon-info"/></span>
                        </el-tooltip>
                    </el-col>
                    <el-col :span="6">
                        <span>当前版本 {{dataForm.armCurVersion}}</span>
                    </el-col>
                    <el-col :span="6" v-if="dataForm.armCurVersion && isArmNew">
                        <span >新版本 {{armLastVersion}}</span>
                    </el-col>
                    <el-col :span="6" v-if="dataForm.armCurVersion && isArmNew">
                        <el-button @click="upgrade('ARM')" type="primary" size="mini">升级</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <el-dialog :visible.sync="isUpgrading" width="320px" :close-on-click-modal="false" :append-to-body="true" center
               :close-on-press-escape="false" :show-close="upgradeFailTimerMs === 0" top="0" :title="upgradeTypeTitle">
            <div class="upgrade-container">
                <div class="top">总包数：{{upgradeInfo.totalDataPacketCount}}，当前包号：{{upgradeInfo.curDataPacketIndex}}</div>
                <el-progress type="circle" :percentage="upgradeInfo.curProgress" :stroke-width="20" :width="170"/>
                <div class="bottom">
                    <div v-if="upgradeFailTimerMs === 0">
                        <span>升级超时，</span>
                        <el-button type="primary" icon="el-icon-refresh" size="mini" plain @click="init()">请重试</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';
import {queryVersionInfo} from "@/api/esaio/device/esaioDeviceUpgradeApi";
import Global from "@/components/Global";

export default {

    name: 'device-schf5k-firmware-upgrade',
    data() {

        return {

            command: {

                commandCodeR: 'get_general_status_parameters'
            },
            deviceSn: '',
            // 属性对应的指令实际值：1：使能，0：禁止.
            dataForm: {

                // 固件当前最新版本
                dspCurVersion: '',
                armCurVersion: '',
            },
            // 服务器最新的固件版本
            dspLastVersion: '',
            armLastVersion: '',
            // 固件是否允许升级
            isDspNew: false,
            isArmNew: false,
            // 升级进度信息
            upgradeInfo: {

                upgradeType: '',
                totalDataPacketCount: 0,
                curDataPacketIndex: 0,
                curProgress: 0
            },
            upgradeInfoTitle: '固件升级',
            upgradeFailTimerId: null,
            upgradeFailTimerMs: 30,
            cmdLoading: null,
            upgradeTypeTitle: '固件升级中',
            isUpgrading: false
        }
    },
    beforeDestroy() {

        this.clearUpgradeFailTimer();
    },
    watch: {

        // 升级类型监听
        'upgradeInfo.upgradeType': {

            handler(newValue) {

                let upgradeType = newValue;
                if (upgradeType === 'DSP') {

                    this.upgradeTypeTitle = 'DSP版本升级中...';
                } else if (upgradeType === 'ARM') {

                    this.upgradeTypeTitle = 'ARM版本升级中...';
                }
            }
        }
    },
    methods: {

        /**
         * 初始化方法.
         * 1、检查设备是否在升级中并打开MQTT连接（处于升级，将直接监听后台升级进度信息）
         * 2、非升级中或者设备离线：
         * (1)直接关闭MQTT连接
         * (2)下发指令，查询设备中的版本号
         * (3)查询服务器上已经发布的固件版本号
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.init();
        },
        init() {

            this.cmdLoading = loading.loading('固件版本读取中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            this.checkIsUpgrade()
                .then(() => this.sendGetVersionCommand())
                .then(() => this.getReleaseVersion())
                .then(() => {
                    this.cmdLoading.close();
                }).catch(() => {

                this.cmdLoading.close();
            })
        },
        /**
         * 检查设备是否在升级中.
         */
        checkIsUpgrade() {

            let that = this;
            return new Promise((resolve, reject) => {

                let option = {

                    server : global.esaioServerBase,
                    url: '/schf5kUpgrade/checkIsUpgrade',
                    data: {

                        sn: this.deviceSn,
                        commandCode: this.command.commandCodeR
                    },
                    isFilterMessageId: false,
                    isAutoDisconnectMqtt: false,
                    topic: global.mqttDeviceCmdTopicPrefix + 'upgrade/' + that.deviceSn,
                    httpSuccessCallback: data => {

                        Object.assign(that.upgradeInfo, data.data);
                        if (that.upgradeInfo.upgradeType == null) {

                            // 不在升级中，先断开MQTT链接
                            mqttService.disconnectMqtt();
                            // 升级超时后，若设备升级完成，用户重试刷新后先关掉上一次的升级弹窗
                            this.isUpgrading = false;
                            // 下发查询设备版本的指令，备注：在这里下发指令的原因是：如果设备在升级中，这个指令是下发不了的
                            resolve();
                        } else {

                            reject();
                            // 设备升级中，若是用户重试刷新，弹窗已开，不需要再次打开
                            if (!that.isUpgrading) {

                                that.isUpgrading = true;
                                that.openUpgradeProcess();
                            }
                            that.curDataPacketIndexChange();
                        }
                    },
                    httpFailCallback: data => {

                        reject();
                        console.log(data);
                        message.error(data.msg);
                        // 升级超时后，用户重试刷新先关掉上一次的升级弹窗
                        this.isUpgrading = false;
                        mqttService.disconnectMqtt();
                    },
                    mqttMessageCallback: (message) => {

                        reject();
                        if (message.code === Global.response_status_success_obj) {

                            Object.assign(that.upgradeInfo, message.data);
                            // 渲染设备升级进度
                            that.curDataPacketIndexChange();
                        } else {

                            mqttService.disconnectMqtt();
                        }
                    }
                }
                mqttService.mqttRequest(option);
            })
        },
        /**
         * 下发指令读取设备中的版本号
         */
        sendGetVersionCommand() {

            let that = this;
            return new Promise((resolve, reject) => {

                let option = {

                    data: {

                        deviceSn: this.deviceSn,
                        commandCode: this.command.commandCodeR
                    },
                    httpFailCallback: function(data) {

                        console.log('获取设备版本http请求失败: ' + data.msg);
                        reject();
                        message.error(data.msg);
                    },
                    mqttMessageCallback: function(res) {

                        if (res.code !== Global.response_status_success_obj) {

                            reject();
                            message.error('请求失败');
                        } else {

                            let data = res.data;
                            that.dataForm.dspCurVersion = data.dspVersion;
                            that.dataForm.armCurVersion = data.armVersion;
                            // 获取发布的版本
                            resolve();
                        }
                    }
                }
                mqttService.sendEsaioBusinessCommand(option);
            })
        },
        /**
         * 获取服务器上已经发布的版本信息.
         */
        getReleaseVersion() {

            let that = this;
            return new Promise((resolve, reject) => {

                let param = {

                    sn: that.deviceSn
                }
                queryVersionInfo(param).then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        that.dspLastVersion = res.data.dspLastVersion;
                        that.armLastVersion = res.data.armLastVersion;
                        that.isDspNew = that.dataForm.dspCurVersion && that.dspLastVersion
                            && (that.dataForm.dspCurVersion !== that.dspLastVersion);
                        that.isArmNew = that.dataForm.armCurVersion && that.armLastVersion
                            && (that.dataForm.armCurVersion !== that.armLastVersion);
                        resolve();
                    } else {

                        reject();
                        message.error(res.msg);
                    }
                }).catch(err => {

                    reject();
                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        upgrade(type) {

            let that = this;
            this.cmdLoading = loading.loading('检查版本...', 30, function () {

                mqttService.disconnectMqtt();
            });
            // 重新查询版本号
            this.sendGetVersionCommand()
                .then(() => this.getReleaseVersion())
                .then(() => {

                    that.cmdLoading.close();
                    // 检测版本是否可升级
                    let content = '';
                    let allowUpgrade = false;
                    if (type === 'DSP' && that.isDspNew) {

                        content = '确认升级DSP版本？';
                        allowUpgrade = true;
                    } else if (type === 'ARM' && that.isArmNew) {

                        content = '确认升级ARM版本？';
                        allowUpgrade = true;
                    }
                    if (!allowUpgrade) {

                        // 已经是最新版本，不需要升级
                        message.warning('已经是最新版本')
                        return;
                    }
                    // 可升级，询问用户是否确认升级
                    message.confirm(content, false, 'warning', ()=>{

                        this.cmdLoading = loading.loading('下发启动升级指令...', 30, function () {

                            mqttService.disconnectMqtt();
                        });
                        // 下发启动升级指令
                        let option = {

                            server : global.esaioServerBase,
                            url: '/schf5kUpgrade/upgrade',
                            isFilterMessageId: false,
                            isAutoDisconnectMqtt: false,
                            topic: global.mqttDeviceCmdTopicPrefix + 'upgrade/' + that.deviceSn,
                            data: {

                                sn: that.deviceSn,
                                type: type
                            },
                            httpFailCallback: data => {

                                that.cmdLoading.close();
                                console.log(data)
                                message.error('启动升级失败');
                                mqttService.disconnectMqtt();
                            },
                            mqttMessageCallback: (res) => {

                                // 关掉指令下发加载圈
                                that.cmdLoading.close();
                                if (res.code === 200) {

                                    Object.assign(that.upgradeInfo, res.data);
                                    // 启动指令回复成功或收到设备索要数据包信息后，渲染升级进度信息
                                    if (!that.isUpgrading) {

                                        that.isUpgrading = true;
                                        // 第一次收到升级返回消息，打开进度框
                                        that.openUpgradeProcess();
                                    }
                                    that.curDataPacketIndexChange();
                                } else {

                                    mqttService.disconnectMqtt();
                                }
                            }
                        }
                        mqttService.mqttRequest(option);
                    })
                }).catch(() => {

                    that.cmdLoading.close();
                })
        },
        openUpgradeProcess() {

            let that = this;
            this.upgradeFailTimerId = setInterval(() => {

                if (that.upgradeFailTimerMs === 0) {

                    mqttService.disconnectMqtt();
                    that.clearUpgradeFailTimer();
                } else {

                    that.upgradeFailTimerMs = that.upgradeFailTimerMs - 1;
                }
            }, 1000);
        },
        // 当前升级包序号发生变化, 更新预计剩余时间和进度
        curDataPacketIndexChange() {

            let curDataPacketIndex = this.upgradeInfo.curDataPacketIndex;
            let totalDataPacketCount = this.upgradeInfo.totalDataPacketCount;
            if (totalDataPacketCount > 0 && curDataPacketIndex === totalDataPacketCount) {

                // 升级成功清掉计时器
                this.clearUpgradeFailTimer();
                this.upgradeInfo.curProgress = 100;
                // 关闭升级进度框
                this.isUpgrading = false;
                // 告诉用户升级成功
                message.success('数据包下发成功，设备正在升级，请稍后');
                // 断开MQTT连接
                mqttService.disconnectMqtt();
            } else {

                this.upgradeFailTimerMs = 30;
                this.upgradeInfo.curProgress = Math.floor(curDataPacketIndex / totalDataPacketCount * 100);
            }
        },
        clearUpgradeFailTimer() {

            if (this.upgradeFailTimerId) {

                clearInterval(this.upgradeFailTimerId);
            }
            this.upgradeFailTimerId = null;
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%;
    .row-gap {

        margin-bottom: 30px;
        width: 600px;
    }
}
.upgrade-container {

    width: 100%;
    text-align: center;
    .top {

        margin-bottom: 10px;
    }
    .bottom {

        margin-top: 10px;
        height: 28px;
    }
}
</style>
